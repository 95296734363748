import Services from "../../../services";

class RegistrasiBarangJadiApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/registrasi_barang_jadi/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getSingle(id_item_buaso) {
    return Services.get(`/registrasi_barang_jadi/single?id_item_buaso=${id_item_buaso}`);
  }
  getAtribut() {
    return Services.get("/registrasi_barang_jadi/atribut");
  }
  getListJenis(params) {
    return Services.get("/registrasi_barang_jadi/jenis", { params });
  }
  getListSatuan() {
    return Services.get("/registrasi_barang_jadi/satuan");
  }
  getListKelompok() {
    return Services.get('/registrasi_barang_jadi/kelompok');
  }
  create(data) {
    return Services.post("/registrasi_barang_jadi", data);
  }
  update(data) {
    return Services.post("/registrasi_barang_jadi/update", data);
  }
  delete(id_item_buaso) {
    return Services.post("/registrasi_barang_jadi/delete", { id_item_buaso });
  }
  search(key) {
    return Services.get(`/registrasi_barang_jadi/page?q=${key}`);
  }
  show(id_item_buaso) {
    return Services.post("/registrasi_barang_jadi/show", id_item_buaso);
  }
  hide(id_item_buaso) {
    return Services.post("/registrasi_barang_jadi/hide", id_item_buaso);
  }
  uploadFileMultiple(data) {
    return Services.post("/upload/registrasi_barang_jadi_multiple", data);
  }
  uploadFile(data) {
    return Services.post("/registrasi_barang_jadi/gambar", data);
  }
  deleteFile(id) {
    return Services.post("/registrasi_barang_jadi/gambar_delete", id);
  }
}

export default new RegistrasiBarangJadiApi();
