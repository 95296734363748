import Services from "services";

class SuratPerjanjianKerja {
  get(params) {
    return Services.get("/spk/page", { params });
  }

  getBarangJadi() {
    return Services.get("/dropdown/barang_jadi");
  }

  getPenawaran(params) {
    return Services.get("/spk/list_penawaran", { params });
  }

  getSingle(params) {
    return Services.get("/spk/single", { params });
  }

  getSinglePenawaran(params) {
    return Services.get("/spk/single_penawaran", { params });
  }

  getNomor(params) {
    return Services.get("/spk/no_baru", { params });
  }

  getNomorAdendum(params) {
    return Services.get("/spk/no_baru_adendum", { params });
  }

  save(values) {
    return Services.post("/spk", values);
  }

  saveAdendum(values) {
    return Services.post("/spk/adendum", values);
  }

  saveBaseline(values) {
    return Services.post("/spk/baseline", values);
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }
}

export default new SuratPerjanjianKerja();
