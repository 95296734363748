// Components
import { Td, Tr } from "components"

const Total = ({ title, children }) => (
	<Tr>
		<Td colSpan={6} textRight className='font-weight-bold'>{title}</Td>
		<Td textRight className='font-weight-bold'>{children}</Td>
	</Tr>
)

export default Total