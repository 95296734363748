// React
import { useState } from "react";

// Components
import {
  ActionButton,
  DataStatus,
  NumberFormat,
  SelectSearch,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import { RupiahConvert } from "utilities";
import { ButtonGroup } from "react-bootstrap";

// Form
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";

// Icons
import {
  IoAddOutline,
  IoPencilOutline,
  IoTrashBinOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5";

const TabAnalisa = ({ dropdown, readOnly, id_buaso, nama_buaso }) => {
  // Variables
  let no = 0;

  // Hooks
  const { values, setFieldValue } = useFormikContext();

  console.log("values", values);

  // States
  const [editIndex, setEditIndex] = useState(null);

  const FormSection = ({ value, isEdit }) => {
    // Hooks
    const formik = useFormik({
      initialValues: {
        id_buaso: value.id_buaso ?? id_buaso,
        id_item_buaso: value.id_item_buaso ?? "",
        kode_item: value.kode_item ?? "",
        nama_item: value.nama_item ?? "",
        qty: value.qty ? parseFloat(value.qty) : "",
        harga: value.harga ?? "",
        kode_satuan: value.kode_satuan ?? "",
        nama_buaso: nama_buaso,
      },
      validationSchema: Yup.object().shape({
        id_item_buaso: Yup.string().required(),
        qty: Yup.number().required(),
      }),
      onSubmit: (value) => {
        if (isEdit) {
          const analisa = values.analisa;
          console.log("cek", analisa);
          analisa.splice(editIndex, 1, value);

          setFieldValue("analisa", analisa);
          setEditIndex(null);
        } else {
          const analisa = values.analisa;
          analisa.push(value);

          setFieldValue("analisa", analisa);
        }
      },
    });

    // Variables
    const harga = formik.values.harga !== "" ? formik.values.harga : 0;
    const qty = formik.values.qty !== "" ? formik.values.qty : 0;
    let dataAtribut = {
      option: [],
      defaultValue: "",
    };

    if (id_buaso === "1") {
      dataAtribut = {
        option: dropdown.item_bahan,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_bahan.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    } else if (id_buaso === "2") {
      dataAtribut = {
        option: dropdown.item_upah,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_upah.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    } else if (id_buaso === "3") {
      dataAtribut = {
        option: dropdown.item_aset,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_aset.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    } else if (id_buaso === "4") {
      dataAtribut = {
        option: dropdown.item_subkon,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_subkon.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    } else if (id_buaso === "5" && nama_buaso !== "BOK") {
      dataAtribut = {
        option: dropdown.item_overhead,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_overhead.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    } else if (id_buaso === "5" && nama_buaso === "BOK") {
      dataAtribut = {
        option: dropdown.item_bok,
        defaultValue: formik.values.id_item_buaso
          ? dropdown.item_bok.find(
              (item) => item.value === formik.values.id_item_buaso
            )
          : "",
      };
    }

    return (
      <tr>
        <Td />
        <Td colSpan={2}>
          <SelectSearch
            label=""
            placeholder={`Pilih Item ${nama_buaso}`}
            onChange={(val) =>
              formik.setValues({
                ...formik.values,
                id_item_buaso: val.value,
                nama_item: val.nama_item,
                kode_item: val.kode_item,
                harga: val.harga,
                kode_satuan: val.kode_satuan,
                nama_buaso: nama_buaso,
              })
            }
            option={dataAtribut.option}
            defaultValue={dataAtribut.defaultValue}
            error={Boolean(
              formik.errors.id_item_buaso && formik.touched.id_item_buaso
            )}
          />
        </Td>
        <Td textRight>
          {RupiahConvert(String(parseFloat(harga))).getWithComa}
        </Td>
        <Td>
          <NumberFormat
            label=""
            placeholder="Qty"
            type="text"
            name="qty"
            decimalScale="3"
            value={formik.values.qty}
            onChange={(value) => formik.setFieldValue("qty", value)}
            error={Boolean(formik.errors.qty && formik.touched.qty)}
          />
        </Td>
        <Td>{formik.values.kode_satuan}</Td>
        <Td textRight>
          {
            RupiahConvert(
              String(Math.round(parseFloat(qty) * parseFloat(harga)))
            ).getWithComa
          }
        </Td>
        <Td>
          {isEdit ? (
            <ButtonGroup>
              <ActionButton
                size="sm"
                variant="success"
                iconOnly
                block
                tooltip
                tooltipText="Simpan"
                onClick={formik.handleSubmit}>
                <IoCheckmarkOutline size="14" />
              </ActionButton>

              <ActionButton
                size="sm"
                variant="danger"
                iconOnly
                block
                tooltip
                tooltipText="Batal"
                onClick={() => setEditIndex(null)}
                className="m-0">
                <IoCloseOutline size="14" />
              </ActionButton>
            </ButtonGroup>
          ) : (
            <ActionButton
              size="sm"
              variant="primary"
              iconOnly
              block
              tooltip
              tooltipText="Simpan"
              onClick={formik.handleSubmit}>
              <IoAddOutline size="14" />
            </ActionButton>
          )}
        </Td>
      </tr>
    );
  };

  return (
    <Table>
      <thead className="bg-light">
        <tr>
          <ThFixed>No.</ThFixed>
          <ThFixed>Kode Item</ThFixed>
          <Th>Item {nama_buaso}</Th>
          <Th style={{ width: "150px" }}>Harga Satuan</Th>
          <Th style={{ width: "100px" }}>Qty. Analisa</Th>
          <Th style={{ width: "100px" }}>Satuan</Th>
          <Th style={{ width: "150px" }}>Sub Total</Th>
          {!readOnly && <ThFixed>Aksi</ThFixed>}
        </tr>
      </thead>
      <tbody>
        {!readOnly && <FormSection value={{}} />}
        {values?.analisa?.filter((val) => val.nama_buaso === nama_buaso)
          .length > 0 ? (
          values.analisa.map((val, index) => {
            const harga = parseFloat(val.harga) ?? 0;
            const qty = parseFloat(val.qty) ?? 0;
            const subtotal = harga * qty;

            if (val.nama_buaso === nama_buaso) {
              no += 1;
              if (index === editIndex) {
                return <FormSection value={val} isEdit />;
              } else {
                return (
                  <tr key={index}>
                    <TdFixed>{no}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>{val.nama_item}</Td>
                    <Td textRight>
                      {harga
                        ? RupiahConvert(String(harga)).getWithComa
                        : RupiahConvert("0").getWithComa}
                    </Td>
                    <Td textRight>{qty}</Td>
                    <Td>{val.kode_satuan}</Td>
                    <Td textRight>
                      {RupiahConvert(String(Math.round(subtotal))).getWithComa}
                    </Td>
                    {!readOnly && (
                      <Td>
                        <ButtonGroup>
                          <ActionButton
                            size="sm"
                            variant="success"
                            iconOnly
                            block
                            tooltip
                            tooltipText="Ubah Data"
                            onClick={() => setEditIndex(index)}>
                            <IoPencilOutline size="14" />
                          </ActionButton>

                          <ActionButton
                            size="sm"
                            variant="danger"
                            iconOnly
                            block
                            tooltip
                            tooltipText="Hapus Data"
                            className="m-0"
                            onClick={() => {
                              const analisa = values.analisa;
                              analisa.splice(index, 1);

                              setFieldValue("analisa", analisa);
                            }}>
                            <IoTrashBinOutline size="14" />
                          </ActionButton>
                        </ButtonGroup>
                      </Td>
                    )}
                  </tr>
                );
              }
            }

            return null;
          })
        ) : (
          <tr>
            <TdFixed colSpan={readOnly ? 7 : 8}>
              <DataStatus text="Tidak Ada Data" />
            </TdFixed>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
export default TabAnalisa;
