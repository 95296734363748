import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  DataStatus,
  Alert,
  BackButton,
  TBody,
  Tr,
  Th,
  ThFixed,
  Td,
  TdFixed,
  TextEditor,
} from "components";
import { InfoPeluangApi } from "api";
import { DateConvert } from "utilities";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { DecimalConvert } from "utilities";
import { InfoItem } from "./Comp";

const DetailInfoPeluang = ({ setNavbarTitle }) => {
  const title = "Info Peluang";
  const history = useHistory();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([{ detail: [] }]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });

  const getData = () => {
    setIsLoading(true);

    InfoPeluangApi.getSingle(id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(false);
    setNavbarTitle("Detail " + title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data.keterangan ?? "<p></p>")
        )
      )
    );
  }, [data]);

  const InfoSection = () => {
    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.9, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark">
          <div>{text ? text : "-"}</div>
        </div>

        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <>
        <Row>
          <Col lg>
            <InfoItem
              title="Tgl. Info Peluang"
              value={
                data.tgl_info_peluang
                  ? DateConvert(new Date(data.tgl_info_peluang)).detail
                  : "-"
              }
            />
            <InfoItem
              title="No. Info Peluang"
              value={data.no_info_peluang ?? "-"}
            />
            <InfoItem title="Customer" value={data.nama_customer ?? "-"} />
            <InfoItem title="Proyek" value={data.nama_proyek ?? "-"} />
          </Col>
          <Col lg>
            <InfoItem
              title="Nama Marketing"
              value={data.nama_marketing ?? "-"}
            />
            <InfoItem title="Jabatan" value={data.nama_jabatan ?? "-"} />
            <InfoItem
              title="Unit Organisasi"
              value={data.nama_unit_organisasi ?? "-"}
            />
          </Col>
        </Row>
        <hr />
        <div className="mb-3">
          <div>Status Info Peluang</div>
          <div
            className={
              data.status_info_peluang === "1"
                ? "text-warning"
                : data.status_info_peluang === "2"
                ? "text-success"
                : "text-primary"
            }>
            <b>{String(data.status_peluang ?? "-").toUpperCase()}</b>
          </div>
          <div>
            {DateConvert(new Date(data.tgl_status_info_peluang)).custom}
          </div>
        </div>
        <TextEditor
          readOnly
          options={[]}
          label="Informasi Lainya"
          editorState={textEditorState}
        />
      </>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <>
        <b>History Catatan Approval {title}</b>
        <Card className="">
          <Card.Body>
            <Row>
              {data?.stakeholder
                ?.filter((val) => val.status_approval !== "PEN")
                .map((val, index) => (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" ||
                        val.status_approval === "REV"
                          ? `Pemeriksa ${
                              val.approval_level !== "0"
                                ? val.approval_level
                                : ""
                            }`
                          : val.status_approval === "APP"
                          ? "Pengesah"
                          : val.status_approval === "PEN"
                          ? "Pembuat"
                          : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                ))}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data {title}</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <DataStatus loading={isLoading} text="Memuat Data" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <CatatanApproval />
            </>
          )}
        </Card.Body>
      </Card>
    </CRUDLayout>
  );
};
export default DetailInfoPeluang;
