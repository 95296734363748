import Services from "services";

class LaporanPenawaranApi {
  getPage(params) {
    return Services.get("/laporan_penawaran/page", { params });
  }

  getPenawaranExport(params) {
    return Services.get("/penawaran_report", { params });
  }
}

export default new LaporanPenawaranApi();
