import Services from "services"

class DashboardApi {
  async getPenawaran(params) {
    const data = await Services.get('/dashboard/penawaran', {params})
    return data.data
  }

  async getPenawaranTahunan(params) {
    const data = await Services.get('/dashboard/penawaran_tahunan', {params})
    return data.data
  }

  async getSPKTahunan(params) {
    const data = await Services.get('/dashboard/spk_tahunan', {params})
    return data.data
  }
}

export default new DashboardApi()