import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import InfoSection from "./InfoSection";
import TableSection from "./TableSection";
import { RegistrasiPeluangApi, AnalisaBarangJadiApi } from "api";
import { DataStatus } from "components";
import { RAEContext } from "../RAEContext";

const AnalisaBarangJadi = ({
  processedData,
  dataBarangJadi,
  setDataBarangJadi,
  modalConfig,
  setModalConfig,
}) => {
  const { indexAnalisa } = useContext(RAEContext);
  const [data, setData] = useState({});
  const [dataListAnalisa, setDataListAnalisa] = useState([]);
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [isFetch, setIsFetch] = useState({ loading: false, success: true });

  const mapAnalisaRAE = (data) =>
    data?.map((item) =>
      Object({
        ...item,
        harga: item.harga_satuan,
      })
    );

  const getInitialData = () => {
    setIsFetch({ loading: true, success: true });
    const { id_rae_detail, id_item_atribut } = processedData;

    Axios.all([
      id_rae_detail
        ? AnalisaBarangJadiApi.getAnalisaRAE({ id_rae_detail })
        : AnalisaBarangJadiApi.getAnalisa({ id_item_atribut }),
      AnalisaBarangJadiApi.getDropdown(),
    ])
      .then(
        Axios.spread((analisa, dropdown) => {
          const dataAnalisa = analisa?.data?.data ?? {};
          const mapDataListAnalisa = id_rae_detail
            ? mapAnalisaRAE(dataAnalisa?.analisa_barang_jadi)
            : dataAnalisa?.analisa; // map data jika get single analisa RAE
          const dataSelect = dropdown?.data?.data?.map((item) =>
            Object({
              ...item,
              value: item.id_item_buaso,
              label: `${item.kode_item} - ${item.nama_item}`,
            })
          );

          let gambar = [];
          let file = [];
          dataAnalisa?.files?.map((val) => {
            const generalFileType = val.path_gambar.split(".").pop();
            if (["jpg", "png", "gif", "jpeg"].includes(generalFileType)) {
              return gambar.push(val);
            }
            return file.push(val);
          });

          setData(dataAnalisa);
          setDataListAnalisa(mapDataListAnalisa);
          setDataGambar(gambar);
          setDataFile(file);
          setDataSelect(dataSelect);

          setIsFetch({ loading: false, success: true });
        })
      )
      .catch((err) => setIsFetch({ loading: false, success: false }));
  };

  useEffect(() => {
    getInitialData();
    return () => {};
  }, []);

  return (
    <>
      {isFetch.loading ? (
        <DataStatus loading={isFetch.loading} text="Memuat data ... " />
      ) : !isFetch.success && !isFetch.loading ? (
        <DataStatus text="Gagal memuat data!" />
      ) : (
        <>
          <InfoSection data={data} dataGambar={dataGambar} dataFile={dataFile} />

          <TableSection
            data={dataListAnalisa}
            dataSelect={dataSelect}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </>
      )}
    </>
  );
};

export default AnalisaBarangJadi;
