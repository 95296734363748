import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoAdd, IoAddOutline } from "react-icons/io5";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
} from "components";
import ModalDetailRAE from "./ModalDetailRAE";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { SuratPerjanjianKerjaApi } from "api";
import { ModalFilter } from "./Section";

const ListPenawaran = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilterPenawaran, setDataFilterPenawaran] = useState({
    active: state?.dataFilterPenawaran?.active,
    tgl_penawaran_mulai: state?.dataFilterPenawaran?.tgl_penawaran_mulai,
    tgl_penawaran_selesai: state?.dataFilterPenawaran?.tgl_penawaran_selesai,
    tgl_rab_mulai: state?.dataFilterPenawaran?.tgl_rab_mulai,
    tgl_rab_selesai: state?.dataFilterPenawaran?.tgl_rab_selesai,
    customer: state?.dataFilterPenawaran?.customer,
    unit_produksi: state?.dataFilterPenawaran?.unit_produksi,
    baseline: state?.dataFilterPenawaran?.baseline,
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: state?.pagination?.page ?? "1",
    dataLength: state?.pagination?.dataLength ?? "10",
    totalPage: state?.pagination?.totalPage ?? "1",
    dataCount: state?.pagination?.dataCount ?? "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });

  const getInitialData = () => {
    setIsPageLoading(true);

    SuratPerjanjianKerjaApi.getPenawaran({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_penawaran_mulai: dataFilterPenawaran?.tgl_penawaran_mulai,
      tgl_penawaran_selesai: dataFilterPenawaran?.tgl_penawaran_selesai,
      tgl_rab_mulai: dataFilterPenawaran?.tgl_rab_mulai,
      tgl_rab_selesai: dataFilterPenawaran?.tgl_rab_selesai,
      customer: dataFilterPenawaran?.customer,
      unit_produksi: dataFilterPenawaran?.unit_produksi,
      baseline: dataFilterPenawaran?.baseline,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Surat Perjanjian Kerja (SPK)");
    // setNavbarTitle("PENAWARAN YANG SIAP DIDAFTARKAN MENJADI SPK");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilterPenawaran?.active,
    dataFilterPenawaran?.tgl_penawaran_mulai,
    dataFilterPenawaran?.tgl_penawaran_selesai,
    dataFilterPenawaran?.tgl_rab_mulai,
    dataFilterPenawaran?.tgl_rab_selesai,
    dataFilterPenawaran?.customer,
    dataFilterPenawaran?.unit_produksi,
    dataFilterPenawaran?.baseline,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Penawaran</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Penawaran</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                {/* <Tr> */}
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div>
                    {DateConvert(new Date(val.tgl_penawaran)).custom ?? "-"}
                  </div>
                  <div>{val.no_penawaran}</div>
                </TdFixed>
                {/* <TdFixed>
                  <div>Tgl Penawaran</div>
                  <div>No Penawaran</div>
                </TdFixed> */}
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_rab)).custom ?? "-"}</div>
                  <div>{val.no_rab}</div>
                </TdFixed>
                {/* <TdFixed>
                  <div>Tgl RAB</div>
                  <div>No RAB</div>
                </TdFixed> */}
                <Td>{val.nama_customer ?? "-"}</Td>
                {/* <Td>Customer</Td> */}
                <Td>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                {/* <Td>Barang Jadi</Td> */}
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                {/* <Td>Unit Produksi</Td> */}
                <TdFixed textCenter>{val.baseline ?? "-"}</TdFixed>
                {/* <TdFixed>Baseline</TdFixed> */}
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => setModalConfig({ show: true, data: val })}
                    />
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="my-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/spk/tambah/${val.id_penawaran}`,
                          { pagination: paginationConfig }
                        )
                      }
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilterPenawaran.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/spk")} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilterPenawaran}
          setData={setDataFilterPenawaran}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {modalConfig.show && (
        <ModalDetailRAE
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPenawaran;
