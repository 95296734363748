import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
    CRUDLayout,
    InputSearch,
    Tr,
    Th,
    Td,
    DataStatus,
    TdFixed,
    ThFixed,
    THead,
    TBody,
    Alert,
    Pagination,
    Select
} from "components";
import { TableNumber, RupiahConvert, DateConvert } from "utilities";
import { LaporanHargaPerkiraanSendiriApi } from "api";

const LaporanHargaPerkiraanSendiri = ({ setNavbarTitle }) => {
    const buaso = [
        { label: "Semua Data", value: "" },
        { label: "Bahan", value: "Bahan" },
        { label: "Upah", value: "Upah" },
        { label: "Alat dan mesin", value: "Alat dan mesin" },
        { label: "Subkon", value: "Subkon" },
        { label: "Overhead", value: "Overhead" },
    ]

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ label: "Semua Data", value: "" });
    const [searchConfig, setSearchConfig] = useState({ status: false, key: "" });
    const [paginationConfig, setPaginationConfig] = useState({
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
    });
    const [alertConfig, setAlertConfig] = useState({
        show: false,
        variant: "primary",
        text: "",
    });
    const [searchTime, setSearchTime] = useState(null)

    const getInitialData = () => {
        setIsLoading(true);

        const query = {
            q: searchConfig.key,
            page: paginationConfig.page,
            per_page: paginationConfig.dataLength,
        }

        if (filter.value !== "") {
            query.grup_buaso = filter.value
        }

        LaporanHargaPerkiraanSendiriApi.getPage(query)
            .then(({ data }) => {
                setData(data.data);
                setPaginationConfig({
                    ...paginationConfig,
                    dataCount: data.data_count,
                    totalPage: data.total_page,
                });
            })
            .catch((err) => {
                setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: "Data gagal dimuat!",
                });
            })
            .finally(() => setIsLoading(false));
    };

    const onInputSearch = (text) => {
        const key = text.target.value;
        if (searchTime) clearTimeout(searchTime)
        setSearchTime(
            setTimeout(() => {
                setSearchConfig({
                    ...searchConfig,
                    key: key,
                });
                setPaginationConfig({
                    page: "1",
                    dataLength: "10",
                    totalPage: "1",
                    dataCount: "0",
                });
                setAlertConfig({
                    show: key ? true : false,
                    variant: "primary",
                    text: "Hasil dari pencarian: " + key,
                });
            }, 750)
        )
    };

    // ON COMPONENT MOUNT
    useEffect(() => {
        setNavbarTitle("Laporan Harga Perkiraan Sendiri"); // SET JUDUL NAVBAR
        getInitialData(); // GET DATA DARI SERVER

        return () => { setIsLoading(false) };
    }, [setNavbarTitle, searchConfig.key, paginationConfig.page, paginationConfig.dataLength, filter.value]);

    // TABLE COMPONENT
    const Table = () => {

        if (!data || data.length < 1) {
            return <DataStatus text="Tidak ada data" />;
        }

        return (
            <>
                <CRUDLayout.Table>
                    <THead>
                        <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Kode Item</ThFixed>
                            <Th>Nama Item</Th>
                            <Th>Grup BUASO</Th>
                            <Th>Satuan Pakai</Th>
                            <Th>HPS Pakai</Th>
                            <Th>Satuan Beli</Th>
                            <Th>HPS Beli</Th>
                            <ThFixed>Tgl. HPS</ThFixed>
                        </Tr>
                    </THead>
                    <TBody>
                        {data.map((val, index) => {
                            return (
                                <Tr key={val.id_item_buaso}>
                                    <TdFixed>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</TdFixed>
                                    <Td className="text-center">{val.kode_item ? val.kode_item : "-"}</Td>
                                    <Td>{val.nama_item ? val.nama_item : "-"}</Td>
                                    <Td>{val.grup_buaso ? val.grup_buaso : "-"}</Td>
                                    <Td>{val.satuan_pakai ? val.satuan_pakai : "-"}</Td>
                                    <Td className="text-right text-nowrap">{val.hps_pakai ? RupiahConvert(String(parseInt(val.hps_pakai))).detail : "Rp 0"}</Td>
                                    <Td>{val.satuan_beli ? val.satuan_beli : "-"}</Td>
                                    <Td className="text-right text-nowrap">{val.hps_beli ? RupiahConvert(String(parseInt(val.hps_beli))).detail : "Rp 0"}</Td>
                                    <Td>{val.tgl_hps ? DateConvert(new Date(val.tgl_hps)).custom : "-"}</Td>
                                </Tr>
                            );
                        })}
                    </TBody>
                </CRUDLayout.Table>
                {!searchConfig.status && (
                    <Pagination
                        dataLength={paginationConfig.dataLength}
                        dataNumber={paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1}
                        dataPage={paginationConfig.dataCount < paginationConfig.dataLength ? paginationConfig.dataCount : paginationConfig.page * paginationConfig.dataLength}
                        dataCount={paginationConfig.dataCount}
                        currentPage={paginationConfig.page}
                        totalPage={paginationConfig.totalPage}
                        onPaginationChange={({ selected }) =>
                            setPaginationConfig({
                                ...paginationConfig,
                                page: selected + 1,
                            })
                        }
                        onDataLengthChange={(e) =>
                            setPaginationConfig({
                                ...paginationConfig,
                                page: 1,
                                dataLength: e.target.value,
                            })
                        }
                    />
                )}
            </>
        );
    };

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadSearchSection>
                    <Row>
                        <Col md="8">
                            <InputSearch onChange={onInputSearch}/>
                        </Col>
                    </Row>
                </CRUDLayout.HeadSearchSection>

                <CRUDLayout.HeadButtonSection>
                    <Select
                        onChange={e => setFilter({
                            label: "",
                            value: e.target.value,
                        })}
                    >
                        {buaso.map(val => <option value={val.value}>{val.label}</option>)}
                    </Select>
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>

            {/* Alert */}
            <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() =>
                    setAlertConfig({
                        ...alertConfig,
                        show: false,
                    })
                }
            />

            {isLoading ? <DataStatus loading={isLoading} text="Memuat Data" /> : <Table />}
        </CRUDLayout>
    );
};

export default LaporanHargaPerkiraanSendiri;
