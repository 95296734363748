import React, { useState, useEffect, useContext, memo } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  TextEditor,
} from "components";
import {
  PenawaranContextProvider,
  PenawaranContext,
} from "../PenawaranContext";
import { FormPenawaran, InfoSection, TableBarangJadi } from "../Section";
import { PenawaranApi } from "api";

const TambahPenawaran = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rab } = useParams();
  const { state } = useLocation();

  const ContentTambahPenawaran = memo(() => {
    const { dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(PenawaranContext);
    const [dataRAB, setDataRAB] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [textEditorState, setTextEditorState] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            "<p>Dengan hormat,<br />Bersama ini kami sampaikan penawaran harga sebagai berikut :</p>"
          )
        )
      );
    });
    const [textEditorStatePenutup, setTextEditorStatePenutup] = useState(() => {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(`<p>Catatan :<br />`))
      );
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      PenawaranApi.getSingleRAB({ id_rab })
        .then((rab) => {
          const mapDataBarangJadi = rab?.data?.data?.detail_barang_jadi?.map(
            (val) =>
              Object({
                ...val,
                satuan: val.nama_satuan_jual,
                qty: val.qty_rab ?? 0,
                harga_satuan: val.unit_cost ?? 0,
                rounded: val.rounded ?? 0,
              })
          );

          setDataRAB(rab?.data?.data ?? {});
          setDataBarangJadi(mapDataBarangJadi ?? []);
        })
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_rab: id_rab,
      tgl_penawaran: "",
      no_penawaran: "",
      diskon: 0,
      ppn: 0,
    };
    const formValidationSchema = Yup.object().shape({
      tgl_penawaran: Yup.string().required("Pilih tanggal Penawaran"),
      no_penawaran: Yup.string().required(
        "Pilih tanggal untuk menentukan nomor Penawaran"
      ),
    });
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        teks_pembuka:
          values.teks_pembuka ??
          draftToHtml(convertToRaw(textEditorState.getCurrentContent())),
        teks_penutup:
          values.teks_penutup ??
          draftToHtml(convertToRaw(textEditorStatePenutup.getCurrentContent())),
      };

      PenawaranApi.save(finalValues)
        .then(() =>
          history.push("/transaksi/penawaran/list-rab", {
            ...state,
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const updateEditorState = (value, setFieldValue, editor) => {
      editor === "teks_pembuka"
        ? setTextEditorState(value)
        : setTextEditorStatePenutup(value);
      const convertData = convertToRaw(value.getCurrentContent());
      setFieldValue(editor, draftToHtml(convertData));
    };

    useEffect(() => {
      setNavbarTitle("Penawaran");
      getInitialData();
    }, []);

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Tambah Data Penawaran</b>
          <div className="d-flex align-items-center justify-content-top">
            <ActionButton
              variant="success"
              text="Ubah RAB"
              size="md"
              className="mb-1 mr-2"
              onClick={() => history.push(`/transaksi/rab/ubah/${id_rab}`)}
            />
            <BackButton
              onClick={() =>
                history.push("/transaksi/penawaran/list-rab", { ...state })
              }
            />
          </div>
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataRAB} />
              <hr />
              <FormPenawaran formik={formik} type="CREATE" />
              <TextEditor
                label="Teks Pembuka"
                placeholder="Tuliskan teks pembuka"
                editorState={textEditorState}
                onEditorStateChange={(val) =>
                  updateEditorState(val, formik.setFieldValue, "teks_pembuka")
                }
                error={
                  formik.errors.teks_pembuka &&
                  formik.touched.teks_pembuka &&
                  true
                }
                errorText={
                  formik.errors.teks_pembuka &&
                  formik.touched.teks_pembuka &&
                  formik.errors.teks_pembuka
                }
              />
              <TableBarangJadi
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                diskon={formik.values.diskon}
                ppn={formik.values.ppn}
                setFieldValue={formik.setFieldValue}
                profit={dataRAB.profit}
              />
              <TextEditor
                label="Teks Penutup"
                placeholder="Tuliskan teks penutup"
                editorState={textEditorStatePenutup}
                onEditorStateChange={(val) =>
                  updateEditorState(val, formik.setFieldValue, "teks_penutup")
                }
                error={
                  formik.errors.teks_penutup &&
                  formik.touched.teks_penutup &&
                  true
                }
                errorText={
                  formik.errors.teks_penutup &&
                  formik.touched.teks_penutup &&
                  formik.errors.teks_penutup
                }
              />
              <div className="mt-4 d-flex justify-content-end align-items-center">
                <ActionButton
                  text="Tambah Penawaran"
                  loading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <PenawaranContextProvider>
      <ContentTambahPenawaran />
    </PenawaranContextProvider>
  );
};

export default TambahPenawaran;
