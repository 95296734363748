/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import Axios from 'axios';
import FileSaver from 'file-saver';
import {
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  Td,
  Th,
} from 'components';
import { MonitoringOrderApi } from 'api';
import { DateConvert, DecimalConvert, PageNumber } from 'utilities';
import { ExportButton, ModalFilter } from './components';

const MonitoringOrder = ({ setNavbarTitle }) => {
  const title = 'Monitoring Order';
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dropdown, setDropdown] = useState({
    customer: [],
    proyek: [],
    barang: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail',
    data: {},
  });
  const [dataFilter, setDataFilter] = useState({
    active: false,
    tgl_sales_order_mulai: undefined,
    tgl_sales_order_selesai: undefined,
    tgl_delivery_order_mulai: undefined,
    tgl_delivery_order_selesai: undefined,
    tgl_surat_jalan_mulai: undefined,
    tgl_surat_jalan_selesai: undefined,
    tgl_jobmix_mulai: undefined,
    tgl_jobmix_selesai: undefined,
    id_customer: undefined,
    id_proyek: undefined,
    id_barang: undefined,
    page: '1',
    per_page: '10',
    totalPage: '1',
    dataCount: '0',
    q: '',
  });

  const fetchDropdown = () => {
    Axios.all([
      MonitoringOrderApi.getDropdownCustomer(),
      MonitoringOrderApi.getDropdownProyek(),
      MonitoringOrderApi.getDropdownBarang(),
    ])
      .then(
        Axios.spread((customer, proyek, barang) => {
          const dataCustomer =
            customer?.data?.data?.map((val) => ({
              value: val.id_customer,
              label: val.nama_customer,
            })) ?? [];
          const dataProyek =
            proyek?.data?.data?.map((val) => ({
              value: val.id_proyek,
              label: val.nama_proyek,
            })) ?? [];
          const dataBarang =
            barang?.data?.data?.map((val) => ({
              value: val.id_item_buaso,
              label: val.nama_item,
            })) ?? [];

          setDropdown({
            customer: dataCustomer,
            proyek: dataProyek,
            barang: dataBarang,
          });
        }),
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        }),
      );
  };

  const getReportData = () => {
    setIsFetchingReport(true);
    const { active, totalPage, dataCount, ...exportParams } = dataFilter;

    MonitoringOrderApi.getPage(exportParams)
      .then(({ data }) => {
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          dataCount: data?.data_count,
          totalPage: data?.total_page,
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        }),
      )
      .finally(() => setIsFetchingReport(false));
  };

  const [showAlert, setShowAlert] = useState(false);
  const [searchTime, setSearchTime] = useState(null);

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setDataFilter({
          ...dataFilter,
          q: key,
        });
        setAlertConfig({
          variant: 'primary',
          text: `hasil pencarian : ${key}`,
        });
        setShowAlert(true);
      }, 750),
    );
  };

  const exportData = (tipe) => {
    setLoadingExport(true);
    const { active, ...exportParams } = dataFilter;

    MonitoringOrderApi.export({
      ...exportParams,
      tipe: tipe,
    })
      .then((res) => {
        const urlFile = res.data.data;
        const httpsUrlFile = urlFile.replace(/^http:/, 'https:');
        FileSaver.saveAs(httpsUrlFile);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Export Data gagal',
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();

    return () => {
      setDropdown({ item_barang: [], vendor: [], karyawan: [] });
      setData([]);
    };
  }, [setNavbarTitle]);

  useEffect(() => {
    getReportData();
  }, [
    dataFilter?.q,
    dataFilter?.tgl_delivery_order_mulai,
    dataFilter?.tgl_sales_order_selesai,
    dataFilter?.tgl_delivery_order_mulai,
    dataFilter?.tgl_delivery_order_selesai,
    dataFilter?.tgl_surat_jalan_mulai,
    dataFilter?.tgl_surat_jalan_selesai,
    dataFilter?.tgl_jobmix_mulai,
    dataFilter?.tgl_jobmix_selesai,
    dataFilter?.id_customer,
    dataFilter?.id_proyek,
    dataFilter?.id_barang,
    dataFilter?.page,
    dataFilter?.per_page,
    dataFilter?.totalPage,
    dataFilter?.dataCount,
    dataFilter?.active,
  ]);

  const PageContent = () => {
    const generateStatusApproval = (data) => {
      const newStatus = data?.status_progress?.toUpperCase();

      switch (newStatus) {
        case 'ON PROGRESS':
          return {
            variant: 'outline-warning',
            textColor: 'text-warning',
            label: 'ON PROGRESS',
            level: 2,
          };
        case 'CLOSED':
          return {
            variant: 'outline-success',
            textColor: 'text-success',
            label: 'CLOSED',
            level: 1,
          };
        default:
          return {
            variant: 'outline-secondary',
            textColor: 'text-secondary',
            label: 'PENDING',
            level: 0,
          };
      }
    };

    const JobmixCollapse = ({ data, satuan }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
            {data.map((val, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${
                      val.tgl_jobmix
                        ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                        : '-'
                    } | ${val.no_jobmix ?? '-'} Qty Produksi ${
                      DecimalConvert(val.qty_jobmix ?? 0).getWithComa
                    } ${satuan} | Terealisasi ${
                      DecimalConvert(val.qty_realisasi ?? 0).getWithComa
                    } ${satuan}`}
                  </li>
                );
              } else {
                return (
                  index <= 2 && (
                    <li key={index} index={index}>
                      {`${
                        val.tgl_jobmix
                          ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                          : '-'
                      } | ${val.no_jobmix ?? '-'} Qty Produksi ${
                        DecimalConvert(val.qty_jobmix ?? 0).getWithComa
                      } ${satuan} | Terealisasi ${
                        DecimalConvert(val.qty_realisasi ?? 0).getWithComa
                      } ${satuan} | ${
                        val.status_progress === 'close'
                          ? 'Closed'
                          : 'On Progress'
                      }`}
                    </li>
                  )
                );
              }
            })}
          </ul>

          {data.length <= 3 ? (
            ''
          ) : (
            <div
              className="text-primary"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
            </div>
          )}
        </>
      );
    };

    const SuratJalanCollapse = ({ data, satuan }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
            {data.map((val, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${
                      val.tgl_surat_jalan
                        ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                        : '-'
                    } | ${val.no_surat_jalan ?? '-'} ${
                      DecimalConvert(val.qty_surat_jalan ?? 0).getWithComa
                    } ${satuan}`}
                  </li>
                );
              } else {
                return (
                  index <= 2 && (
                    <li key={index} index={index}>
                      {`${
                        val.tgl_surat_jalan
                          ? DateConvert(new Date(val.tgl_surat_jalan))
                              .defaultDMY
                          : '-'
                      } | ${val.no_surat_jalan ?? '-'} ${
                        DecimalConvert(val.qty_surat_jalan ?? 0).getWithComa
                      } ${satuan}`}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length <= 3 ? (
            ''
          ) : (
            <div
              className="text-primary"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
            </div>
          )}
        </>
      );
    };

    return (
      <>
        <div className="font-weight-bold mb-2">List Data Sales Order</div>
        <Table>
          <thead>
            <tr>
              <Th width="12px">No.</Th>
              <Th width="12px">Informasi Sales Order</Th>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Barang Jadi</Th>
              <Th className="text-nowrap">Qty. Sales Order</Th>
              <Th width="12px">Informasi Delivery Order</Th>
              <Th width="12px">Informasi Surat Jalan</Th>
              <Th width="12px">Informasi Produksi</Th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {PageNumber(dataFilter?.page, dataFilter?.per_page, index)}
                  </Td>
                  <Td>
                    <div>
                      {DateConvert(new Date(val.tgl_sales_order)).defaultDMY}
                    </div>
                    <div>{val.no_sales_order ?? ''}</div>
                  </Td>
                  <Td className="text-nowrap">{val.nama_customer ?? '-'}</Td>
                  <Td className="text-nowrap">{val.nama_proyek ?? '-'}</Td>
                  <Td className="text-nowrap">{val.nama_barang ?? '-'}</Td>
                  <Td className="text-right">
                    {DecimalConvert(val.qty_sales_order ?? 0).getWithComa}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {val.tgl_delivery_order
                        ? DateConvert(new Date(val.tgl_delivery_order))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_delivery_order ?? '-'}</div>
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <SuratJalanCollapse
                      data={val?.surat_jalan ?? []}
                      satuan={val.kode_satuan ?? '-'}
                    />
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <JobmixCollapse
                      data={val.jobmix ?? []}
                      satuan={val.kode_satuan ?? '-'}
                    />
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className="align-middle text-nowrap text-center"
                >
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.per_page}
            dataNumber={
              dataFilter?.page * dataFilter?.per_page - dataFilter?.per_page + 1
            }
            dataPage={
              dataFilter?.dataCount < dataFilter?.per_page
                ? dataFilter?.dataCount
                : dataFilter?.page * dataFilter?.per_page
            }
            dataCount={dataFilter?.dataCount}
            currentPage={dataFilter?.page}
            totalPage={dataFilter?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                page: selected + 1,
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                page: 1,
                per_page: e.target.value,
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch onChange={onInputSearch} />
        </div>

        <div className="d-flex">
          <FilterButton
            active={dataFilter?.active}
            onClick={() => setModalConfig({ show: true, type: 'filter' })}
            className="mr-2"
          />
          <ExportButton
            loading={loadingExport}
            disabled={
              !(
                dataFilter.tgl_sales_order_mulai &&
                dataFilter.tgl_sales_order_selesai
              )
            }
          >
            <Dropdown.Item onClick={() => exportData('excel')}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportData('pdf')}>
              PDF (.pdf)
            </Dropdown.Item>
          </ExportButton>
        </div>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: '45vh' }}>
          <PageContent />
        </div>
      )}

      {modalConfig.show && modalConfig.type === 'filter' && (
        <ModalFilter
          dropdown={dropdown}
          setDropdown={setDropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default MonitoringOrder;
