import { ApprovalStatusButton } from "components";
import { Card } from "react-bootstrap";
import { DateConvert } from "utilities";
import { getStatusApprovalLabel } from "../../functions";

const ApprovalSection = ({ data }) => (
  <>
    <b>Informasi Approval</b>
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-start">
          <div className="mr-5">
            <small>Status Approval</small>
            <ApprovalStatusButton
              variant={getStatusApprovalLabel(data.status_approval).variant}
            >
              {getStatusApprovalLabel(data.status_approval).label}
            </ApprovalStatusButton>
          </div>

          <div>
            <small>Update Terakhir</small>
            <div className="font-weight-bold">
              {DateConvert(new Date(data.approval_created_at)).defaultDMY}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  </>
);

export default ApprovalSection;
