import Services from "services";

class RAEApi {
  getPage(params) {
    return Services.get("/rae/page", { params });
  }

  getPageListPeluang(params) {
    return Services.get('/rae/list_peluang', { params });
  }

  getNomorRAE(params) {
    return Services.get("/rae/no_baru", { params });
  }

  getSinglePeluang(params) {
    return Services.get("/rae/detail_peluang", { params });
  }

  getSingleRAE(params) {
    return Services.get("/rae/single", { params })
  }

  getDataSelectBarangJadi() {
    return Services.get("/dropdown/barang_jadi");
  }

  save(data) {
    return Services.post('/rae/', data);
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }

  getDropdownPeringkatPeluang() {
    return Services.get("/peluang/peringkat_peluang");
  }

  getDropdownProyek() {
    return Services.get("/dropdown/proyek");
  }
}

export default new RAEApi();
