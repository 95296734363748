import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  DataStatus,
  Alert,
  BackButton,
  TBody,
  Tr,
  Th,
  ThFixed,
  Td,
  TdFixed,
  TextEditor,
} from "components";
import { RegistrasiPeluangApi } from "api";
import { DateConvert } from "utilities";
import AnalisaBarangJadiPeluang from "./AnalisaBarangJadiPeluang";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { DecimalConvert } from "utilities";

const DetailRegistrasiPeluang = ({ setNavbarTitle }) => {
  const title = "Peluang";
  const history = useHistory();
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([{ detail: [] }]);
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });

  const getData = () => {
    setIsLoading(true);

    RegistrasiPeluangApi.getSingle(id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setIsLoading(false);
    setNavbarTitle("Detail " + title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  useEffect(() => {
    setTextEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(data.lain_lain ?? "<p></p>")
        )
      )
    );
  }, [data]);

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.9, textTransform: "capitalize" }}>
          {title}
        </small>
        <div className="text-dark">
          <div>{text ? text : "-"}</div>
        </div>

        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <>
        <Row className="mb-4">
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    data?.tgl_peluang
                      ? DateConvert(new Date(data.tgl_peluang)).detail
                      : "-"
                  }
                />
                <InfoItem title="No. Peluang" value={data?.no_peluang ?? "-"} />
                <InfoItem
                  title="Peringkat Peluang"
                  value={data?.nama_peringkat_peluang ?? "-"}
                />
                <InfoItem title="ATT" value={data?.att ?? "-"} />
                <InfoItem
                  title="Unit Produksi"
                  value={data?.nama_unit_produksi ?? "-"}
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Info Peluang"
                  value={
                    data?.tgl_info_peluang
                      ? DateConvert(new Date(data.tgl_info_peluang)).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="No. Info Peluang"
                  value={data?.no_info_peluang ?? "-"}
                />
                <InfoItem
                  title="Nama Marketing"
                  value={data?.nama_marketing ?? "-"}
                />
                <InfoItem title="Customer" value={data?.nama_customer ?? "-"} />
                <InfoItem title="Proyek" value={data?.nama_proyek ?? "-"} />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md>
            <ShowData
              title="Keterangan Pengiriman"
              text={data.keterangan_pengiriman ?? "-"}
              line={true}
            />
          </Col>
          <Col md>
            <ShowData
              title="Lokasi Pengiriman"
              text={data.lokasi_pengiriman ?? "-"}
              line={true}
            />
          </Col>
        </Row>
        <TextEditor
          readOnly
          options={[]}
          label="Informasi Lainya"
          editorState={textEditorState}
        />
      </>
    );
  };

  const TableSection = () => {
    return (
      <>
        <div className="my-2">
          <b>List Barang Jadi</b>
        </div>
        <CRUDLayout.Table>
          <thead className="bg-light">
            <Tr>
              <ThFixed>No</ThFixed>
              <Th style={{ width: 100 }}>Kode Barang Jadi</Th>
              <Th>Barang Jadi</Th>
              <Th>Jenis Barang Jadi</Th>
              <Th>Satuan</Th>
              <Th>Qty</Th>
            </Tr>
          </thead>
          <TBody>
            {data.detail.length > 0 ? (
              data.detail.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcessedData({
                          ...val,
                          index: index,
                          id_barang_jadi: val.id_item_buaso,
                        });
                        setModalConfig({
                          show: true,
                          type: "analisa",
                          title: (
                            <span className="text-primary">
                              Analisa Barang Jadi Peluang
                            </span>
                          ),
                        });
                      }}>
                      {val.nama_item}
                    </a>
                  </Td>
                  <Td>{val.nama_jenis ?? "-"}</Td>
                  <Td>{val.nama_satuan_jual ?? "-"}</Td>
                  <Td>
                    <div className="text-right">
                      {DecimalConvert(val.qty).getWithComa}
                    </div>
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6" className="py-3 text-center">
                  <span style={{ fontSize: 14 }}>Tidak ada data</span>
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  const ModalSection = ({ processedData, modalConfig, setModalConfig }) => {
    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "analisa" ? "xl" : "md"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}>
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AnalisaBarangJadiPeluang processedData={processedData} />
        </Modal.Body>
      </Modal>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Peluang</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {data?.stakeholder?.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" ||
                    val.status_approval === "REV"
                      ? `Pemeriksa ${
                          val.approval_level !== "0" ? val.approval_level : ""
                        }`
                      : val.status_approval === "APP"
                      ? "Pengesah"
                      : val.status_approval === "PEN"
                      ? "Pembuat"
                      : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card className="pb-3 mt-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data {title}</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <DataStatus loading={isLoading} text="Memuat Data" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSection />
              <CatatanApproval />
            </>
          )}
        </Card.Body>
      </Card>

      <ModalSection
        processedData={processedData}
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
    </CRUDLayout>
  );
};
export default DetailRegistrasiPeluang;
