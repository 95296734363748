import React, { useState, useEffect, useContext, memo } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { RAEContextProvider, RAEContext } from "../RAEContext";
import { FormRAE, InfoSection, TableBarangJadi } from "../Section";
import { RAEApi } from "api";

const UbahRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rae } = useParams();
  const { state } = useLocation();

  const ContentUbahRAE = memo(() => {
    const { setType, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } = useContext(RAEContext);
    const [dataRAE, setDataRAE] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [dataSelectBarangJadi, setDataSelectBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true);
      Axios.all([RAEApi.getSingleRAE({ id_rae }), RAEApi.getDataSelectBarangJadi()])
        .then(
          Axios.spread((rae, selectBarangJadi) => {
            const mapSelectBarangJadi = selectBarangJadi?.data?.data?.map((val) =>
              Object({
                ...val,
                value: val.id_item_buaso,
                label: `${val.kode_item} - ${val.nama_item}`,
                nama_satuan: val.nama_satuan_jual,
                harga: val.total_analisa,
              })
            );

            const mapDataBarangJadi = rae?.data?.data?.detail?.map((val) =>
              Object({
                ...val,
                qty: val.qty_rae,
                harga_satuan: val.harga_satuan_rae,
                satuan: val.nama_satuan_jual,
                unique: Math.random().toString(36).substring(2, 9),
              })
            );

            setDataRAE(rae?.data?.data ?? {});
            setDataSelectBarangJadi(mapSelectBarangJadi ?? []);
            setDataBarangJadi(mapDataBarangJadi ?? []);
          })
        )
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_rae: id_rae,
      id_peluang: dataRAE?.id_peluang,
      tgl_rae: dataRAE?.tgl_rae ?? "",
      no_rae: dataRAE?.no_rae ?? "",
      baseline: dataRAE?.baseline ?? "1",
    };
    const formValidationSchema = Yup.object().shape({
      tgl_rae: Yup.string().required("Pilih tanggal RAE"),
      no_rae: Yup.string().required("Pilih tanggal untuk menentukan nomor RAE"),
    });
    const formSubmitHandler = (values) => {
      const mapAnalisaBarangJadi = dataAnalisaBarangJadi?.map((item) => ({
        unique: item.unique,
        analisa: item?.analisa?.map((val) =>
          Object({
            id_item_buaso: val.id_item_buaso,
            id_item_atribut: val.id_item_atribut,
            id_buaso: val.id_buaso,
            qty: val.qty,
            harga_satuan: val.harga,
          })
        ),
      }));

      const mapBarangJadi = dataBarangJadi?.map((item) => ({
        id_rae_detail: item.id_rae_detail,
        id_item_atribut: item.id_item_atribut,
        qty_rae: item.qty,
        harga_satuan_rae: item.harga_satuan ?? 0,
        analisa_barang_jadi:
          mapAnalisaBarangJadi?.find((val) => item.unique === val.unique)?.analisa ?? [],
      }));

      const finalValues = {
        ...values,
        detail_barang_jadi: mapBarangJadi,
      };

      RAEApi.save(finalValues)
        .then(() =>
          history.push("/transaksi/rae", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Estimasi (RAE)");
      setType("UPDATE");
      getInitialData();
    }, []);

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data RAE</b>
          <BackButton onClick={() => history.push("/transaksi/rae", { ...state })} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataRAE} />
              <hr />
              <FormRAE formik={formik} type="UPDATE" />
              <hr />
              <TableBarangJadi
                dataSelectBarangJadi={dataSelectBarangJadi}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
              />
              <div className="mt-4 d-flex justify-content-end align-items-center">
                <ActionButton
                  variant="success"
                  text="Ubah Rencana Anggaran Estimasi"
                  loading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <RAEContextProvider>
      <ContentUbahRAE />
    </RAEContextProvider>
  );
};

export default UbahRAE;
