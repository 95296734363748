import React from "react";

const InfoItem = ({ title, value }) => {
  return (
    <div className="d-flex">
      <div style={{ width: 150 }}>{title}</div>
      <div style={{ width: 20 }}>:</div>
      <div>{value}</div>
    </div>
  );
};

export default InfoItem;
