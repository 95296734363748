import { createContext, useState } from "react";

export const RABContext = createContext();

export const RABContextProvider = ({ children }) => {
  const [indexAnalisa, setIndexAnalisa] = useState("");
  const [type, setType] = useState("");
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([]);

  return (
    <RABContext.Provider
      value={{
        indexAnalisa,
        type,
        dataAnalisaBarangJadi,
        setIndexAnalisa,
        setType,
        setDataAnalisaBarangJadi,
      }}
    >
      {children}
    </RABContext.Provider>
  );
};
