import Logo from "../assets/image/LogoTJS.jpeg";

import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

// IMPORT PAGE
import {
  // MASTER
  PeringkatPeluang,
  RegistrasiBarangJadi,
  DetailRegistrasiBarangJadi,
  TambahRegistrasiBarangJadi,
  UpdateRegistrasiBarangJadi,

  // TRANSAKSI
  RegistrasiPeluang,
  TambahRegistrasiPeluang,
  UbahRegistrasiPeluang,
  DetailRegistrasiPeluang,
  RAE,
  PeluangRAE,
  TambahRAE,
  UbahRAE,
  DetailRAE,
  RAB,
  ListRAE,
  TambahRAB,
  UbahRAB,
  DetailRAB,
  Penawaran,
  ListRAB,
  TambahPenawaran,
  UbahPenawaran,
  DetailPenawaran,
  SPK,
  ListPenawaranSPK,
  DetailSPK,
  TambahSPK,
  UbahSPK,
  MonitoringOrder,

  // LAPORAN
  LaporanHargaPerkiraanSendiri,
  LogProgressProduksi,
  ProgressProduksi,
  LaporanPeluang,
  LaporanPenawaran,
  LaporanSPK,
  LaporanGeneralMarketing,
  LaporanHargaKubikasiKayu,
  InfoPeluang,
  FormInfoPeluang,
  ListInfoPeluang,
  AdendumSPK,
  AnalisaBarangJadiList,
} from "../pages/MKT";
import { DetailInfoPeluang } from "pages/MKT/Transaksi/InfoPeluang";
import { DetailAnalisaBarangJadi } from "pages/MKT/Master";

export default {
  LOGO: Logo,
  MODUL: "Marketing",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["MKT"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["MKT", "MKT_MST_ABJ", "MKT_MAS_PKP"],
      menu: [
        {
          text: "Peringkat Peluang",
          link: "/master/peringkat-peluang",
          hak: ["SUPA", "MKT_MAS_PKP"],
        },
        {
          text: "Analisa Barang Jadi",
          link: "/master/analisa-barang-jadi",
          hak: ["SUPA", "MKT_MST_ABJ"],
        },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: [
        "MKT",
        "MKT_TRN_IRP",
        "MKT_TRN_RP",
        "MKT_TRN_RAE",
        "MKT_TRN_RAB",
        "MKT_TRN_PNW",
        "MKT_TRN_SPK",
      ],
      menu: [
        {
          text: "Info Peluang",
          link: "/transaksi/info-peluang",
          hak: ["SUPA", "MKT_TRN_IRP"],
        },
        {
          text: "Peluang",
          link: "/transaksi/registrasi-peluang",
          hak: ["SUPA", "MKT_TRN_RP"],
        },
        // {
        //   text: "RAE",
        //   link: "/transaksi/rae",
        //   hak: ["SUPA", "MKT_TRN_RAE"],
        // },
        {
          text: "RAB",
          link: "/transaksi/rab",
          hak: ["SUPA", "MKT_TRN_RAB"],
        },
        {
          text: "Penawaran",
          link: "/transaksi/penawaran",
          hak: ["SUPA", "MKT_TRN_PNW"],
        },
        {
          text: "Surat Perjanjian Kerja",
          link: "/transaksi/spk",
          hak: ["SUPA", "MKT_TRN_SPK"],
        },
        {
          text: 'Monitoring Order',
          link: '/transaksi/monitoring-order',
          hak: ['SUPA', 'MKT_TRN_MTO'],
        }
      ],
    },
    // {
    //   text: "Laporan",
    //   type: "dropdown",
    //   icon: <IoDocumentTextOutline />,
    //   hak: ["MKT"],
    //   menu: [
    //     {
    //       text: "Harga Perkiraan Sendiri",
    //       link: "/laporan/harga-perkiraan-sendiri",
    //       hak: ["SUPA", "MKT_RPT_HPS"]
    //     },
    //     {
    //       text: "Progress Produksi",
    //       link: "/laporan/progress-produksi",
    //       hak: ["SUPA", "MKT_RPT_PRP"]
    //     },
    //   ],
    // },

    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["MKT"],
      menu: [
        {
          text: "Harga Perkiraan Sendiri",
          link: "/laporan/harga-perkiraan-sendiri",
          hak: ["SUPA", "MKT_RPT_HPS"],
        },
        {
          text: "Progress Produksi",
          link: "/laporan/progress-produksi",
          hak: ["SUPA", "MKT_RPT_PRP"],
        },
        {
          text: "Laporan Peluang",
          link: "/laporan/peluang",
          hak: ["SUPA", "MKT_RPT_PLG"],
        },
        {
          text: "Laporan Penawaran",
          link: "/laporan/penawaran",
          hak: ["SUPA", "MKT_RPT_PNW"],
        },
        {
          text: "Laporan SPK",
          link: "/laporan/spk",
          hak: ["SUPA", "MKT_RPT_SPK"],
        },
        {
          text: "Laporan General Marketing",
          link: "/laporan/general-marketing",
          hak: ["SUPA", "MKT_RPT_GMKT"],
        },
        // {
        //   text: "Laporan Harga Kubikasi Kayu",
        //   link: "/laporan/harga-kubikasi-kayu",
        //   hak: ["SUPA", "MKT_RPT_HKK"],
        // },
      ],
    },

    // {
    //   text: "Profil",
    //   type: "dropdown",
    //   icon: <IoPersonCircleOutline />,
    //   hak: ["MKT"],
    //   menu: [
    //     {
    //       text: "Akun Saya",
    //       link: "/profil",
    //       hak: ["MKT"],
    //     },
    //     {
    //       text: "Ubah Akun",
    //       link: "/profil/ubah",
    //       hak: ["MKT"],
    //     },
    //     {
    //       text: "Ganti Password",
    //       link: "/profil/ganti-password",
    //       hak: ["MKT"],
    //     },
    //   ],
    // },
  ],

  ROUTES: [
    // MASTER
    // PERINGKAT PELUANG
    {
      exact: true,
      path: "/master/peringkat-peluang",
      page: PeringkatPeluang,
      hak: ["SUPA", "MKT_MAS_PKP"],
    },
    {
      exact: true,
      path: "/master/analisa-barang-jadi",
      page: AnalisaBarangJadiList,
      hak: ["SUPA", "MKT_MST_ABJ"],
    },
    {
      exact: true,
      path: "/master/analisa-barang-jadi/detail/:id_analisa_barang_jadi",
      page: DetailAnalisaBarangJadi,
      hak: ["SUPA", "MKT_MST_ABJ"],
    },

    // REGISTRASI BARANG JADI
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi",
    //   page: RegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/tambah",
    //   page: TambahRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/update/:id_item_buaso",
    //   page: UpdateRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/detail/:id_item_buaso",
    //   page: DetailRegistrasiBarangJadi,
    //   hak: ["SUPA", "MKT_MAS_BRJ"],
    // },

    // TRANSAKSI
    // Info Peluang
    {
      exact: true,
      path: "/transaksi/info-peluang",
      page: InfoPeluang,
      hak: ["SUPA", "MKT_TRN_IRP"],
    },
    {
      exact: true,
      path: "/transaksi/info-peluang/tambah",
      page: FormInfoPeluang,
      hak: ["SUPA", "MKT_TRN_IRP"],
    },
    {
      exact: true,
      path: "/transaksi/info-peluang/ubah/:id",
      page: FormInfoPeluang,
      hak: ["SUPA", "MKT_TRN_IRP"],
    },
    {
      exact: true,
      path: "/transaksi/info-peluang/detail/:id",
      page: DetailInfoPeluang,
      hak: ["SUPA", "MKT_TRN_IRP"],
    },

    // REGISTRASI PELUANG
    {
      exact: true,
      path: "/transaksi/registrasi-peluang",
      page: RegistrasiPeluang,
      hak: ["SUPA", "MKT_TRN_RP"],
    },
    {
      exact: true,
      path: "/transaksi/registrasi-peluang/list-info-peluang",
      page: ListInfoPeluang,
      hak: ["SUPA", "MKT_TRN_RP"],
    },
    {
      exact: true,
      path: "/transaksi/registrasi-peluang/list-info-peluang/tambah/:id",
      page: TambahRegistrasiPeluang,
      hak: ["SUPA", "MKT_TRN_RP"],
    },
    {
      exact: true,
      path: "/transaksi/registrasi-peluang/ubah/:id",
      page: UbahRegistrasiPeluang,
      hak: ["SUPA", "MKT_TRN_RP"],
    },
    {
      exact: true,
      path: "/transaksi/registrasi-peluang/detail/:id",
      page: DetailRegistrasiPeluang,
      hak: ["SUPA", "MKT_TRN_RP"],
    },

    // RAE
    {
      exact: true,
      path: "/transaksi/rae",
      page: RAE,
      hak: ["SUPA", "MKT_TRN_RAE"],
    },
    {
      exact: true,
      path: "/transaksi/rae/list-peluang",
      page: PeluangRAE,
      hak: ["SUPA", "MKT_TRN_RAE"],
    },
    {
      exact: true,
      path: "/transaksi/rae/tambah/:id_peluang",
      page: TambahRAE,
      hak: ["SUPA", "MKT_TRN_RAE"],
    },
    {
      exact: true,
      path: "/transaksi/rae/ubah/:id_rae",
      page: UbahRAE,
      hak: ["SUPA", "MKT_TRN_RAE"],
    },
    {
      exact: true,
      path: "/transaksi/rae/detail/:id_rae",
      page: DetailRAE,
      hak: ["SUPA", "MKT_TRN_RAE"],
    },

    // RAB
    {
      exact: true,
      path: "/transaksi/rab",
      page: RAB,
      hak: ["SUPA", "MKT_TRN_RAB"],
    },
    {
      exact: true,
      path: "/transaksi/rab/list-peluang",
      page: ListRAE,
      hak: ["SUPA", "MKT_TRN_RAB"],
    },
    {
      exact: true,
      path: "/transaksi/rab/tambah/:id_rae",
      page: TambahRAB,
      hak: ["SUPA", "MKT_TRN_RAB"],
    },
    {
      exact: true,
      path: "/transaksi/rab/ubah/:id_rab",
      page: UbahRAB,
      hak: ["SUPA", "MKT_TRN_RAB"],
    },
    {
      exact: true,
      path: "/transaksi/rab/detail/:id_rab",
      page: DetailRAB,
      hak: ["SUPA", "MKT_TRN_RAB"],
    },

    // PENAWARAN
    {
      exact: true,
      path: "/transaksi/penawaran/",
      page: Penawaran,
      hak: ["SUPA", "MKT_TRN_PNW"],
    },
    {
      exact: true,
      path: "/transaksi/penawaran/list-rab",
      page: ListRAB,
      hak: ["SUPA", "MKT_TRN_PNW"],
    },
    {
      exact: true,
      path: "/transaksi/penawaran/tambah/:id_rab",
      page: TambahPenawaran,
      hak: ["SUPA", "MKT_TRN_PNW"],
    },
    {
      exact: true,
      path: "/transaksi/penawaran/ubah/:id_penawaran",
      page: UbahPenawaran,
      hak: ["SUPA", "MKT_TRN_PNW"],
    },
    {
      exact: true,
      path: "/transaksi/penawaran/detail/:id_penawaran",
      page: DetailPenawaran,
      hak: ["SUPA", "MKT_TRN_PNW"],
    },

    // SURAT PENAWARAN KERJA
    {
      exact: true,
      path: "/transaksi/spk",
      page: SPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: "/transaksi/spk/list-penawaran",
      page: ListPenawaranSPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: "/transaksi/spk/tambah/:id_penawaran",
      page: TambahSPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: "/transaksi/spk/ubah/:id_spk",
      page: UbahSPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: "/transaksi/spk/adendum/:id_spk",
      page: AdendumSPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: "/transaksi/spk/detail/:id_spk",
      page: DetailSPK,
      hak: ["SUPA", "MKT_TRN_SPK"],
    },
    {
      exact: true,
      path: '/transaksi/monitoring-order',
      page: MonitoringOrder,
      hak: ['SUPA', 'MKT_TRN_MTO'],
    },

    // LAPORAN
    {
      exact: true,
      path: "/laporan/harga-perkiraan-sendiri",
      page: LaporanHargaPerkiraanSendiri,
      hak: ["SUPA", "MKT_RPT_HPS"],
    },
    {
      exact: true,
      path: "/laporan/progress-produksi",
      page: ProgressProduksi,
      hak: ["SUPA", "MKT_RPT_PRP"],
    },
    {
      exact: true,
      path: "/laporan/progress-produksi/log",
      page: LogProgressProduksi,
      hak: ["SUPA", "MKT_RPT_PRP"],
    },
    {
      exact: true,
      path: "/laporan/peluang",
      page: LaporanPeluang,
      hak: ["SUPA", "MKT_RPT_PLG"],
    },
    {
      exact: true,
      path: "/laporan/penawaran",
      page: LaporanPenawaran,
      hak: ["SUPA", "MKT_RPT_PNW"],
    },
    {
      exact: true,
      path: "/laporan/spk",
      page: LaporanSPK,
      hak: ["SUPA", "MKT_RPT_SPK"],
    },
    {
      exact: true,
      path: "/laporan/general-marketing",
      page: LaporanGeneralMarketing,
      hak: ["SUPA", "MKT_RPT_GMKT"],
    },
    {
      exact: true,
      path: "/laporan/harga-kubikasi-kayu",
      page: LaporanHargaKubikasiKayu,
      hak: ["SUPA", "MKT_RPT_HKK"],
    },
  ],
};
