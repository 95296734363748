import React, { useState, useEffect, useContext, memo } from "react";
import { Card } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { RAEContextProvider, RAEContext } from "../RAEContext";
import { FormRAE, InfoSection, TableBarangJadi } from "../Section";
import { RAEApi } from "api";

const TambahRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_peluang } = useParams();
  const { state } = useLocation();

  const ContentTambahRAE = memo(() => {
    const { setType, dataAnalisaBarangJadi, setDataAnalisaBarangJadi } =
      useContext(RAEContext);
    const [dataPeluang, setDataPeluang] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [dataSelectBarangJadi, setDataSelectBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true);
      Axios.all([
        RAEApi.getSinglePeluang({ id_peluang }),
        RAEApi.getDataSelectBarangJadi(),
      ])
        .then(
          Axios.spread((peluang, selectBarangJadi) => {
            const mapSelectBarangJadi = selectBarangJadi?.data?.data?.map(
              (val) =>
                Object({
                  ...val,
                  value: val.id_item_atribut,
                  label: `${val.kode_item} - ${val.nama_item}`,
                  nama_satuan: val.nama_satuan_jual,
                  harga: val.total_analisa,
                })
            );

            const mapDataBarangJadi = peluang?.data?.data?.detail?.map((val) =>
              Object({
                ...val,
                satuan: val.nama_satuan_jual,
                harga_satuan: val.total_analisa,
                unique: Math.random().toString(36).substring(2, 9),
              })
            );

            setDataPeluang(peluang?.data?.data ?? {});
            setDataSelectBarangJadi(mapSelectBarangJadi ?? []);
            setDataBarangJadi(mapDataBarangJadi ?? []);
          })
        )
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const formInitialValues = {
      id_peluang: id_peluang,
      tgl_rae: "",
      no_rae: "",
      baseline: "1",
    };
    const formValidationSchema = Yup.object().shape({
      tgl_rae: Yup.string().required("Pilih tanggal RAE"),
      no_rae: Yup.string().required("Pilih tanggal untuk menentukan nomor RAE"),
    });
    const formSubmitHandler = (values) => {
      const mapAnalisaBarangJadi = dataAnalisaBarangJadi?.map((item) => ({
        unique: item.unique,
        analisa: item?.analisa?.map((val) =>
          Object({
            id_item_buaso: val.id_item_buaso,
            id_item_atribut: val.id_item_atribut,
            id_buaso: val.id_buaso,
            qty: val.qty,
            harga_satuan: val.harga,
          })
        ),
      }));

      const mapBarangJadi = dataBarangJadi?.map((item) => ({
        id_item_atribut: item.id_item_atribut,
        qty_rae: item.qty,
        harga_satuan_rae: item.harga_satuan ?? 0,
        analisa_barang_jadi:
          mapAnalisaBarangJadi?.find((val) => item.unique === val.unique)
            ?.analisa ?? [],
      }));

      const finalValues = {
        ...values,
        detail_barang_jadi: mapBarangJadi,
      };

      RAEApi.save(finalValues)
        .then(() =>
          history.push("/transaksi/rae/list-peluang", {
            ...state,
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: "Data gagal ditambah!",
          });
        });
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    useEffect(() => {
      setNavbarTitle("Rencana Anggaran Estimasi (RAE)");
      setType("CREATE");
      getInitialData();
    }, []);

    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Tambah Data RAE</b>
          <BackButton
            onClick={() =>
              history.push("/transaksi/rae/list-peluang", { ...state })
            }
          />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
          ) : (
            <>
              <InfoSection data={dataPeluang} />
              <hr />
              <FormRAE formik={formik} type="CREATE" />
              <hr />
              <TableBarangJadi
                dataSelectBarangJadi={dataSelectBarangJadi}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
              />
              <div className="mt-4 d-flex justify-content-end align-items-center">
                <ActionButton
                  text="Tambah Rencana Anggaran Estimasi"
                  loading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    );
  });

  return (
    <RAEContextProvider>
      <ContentTambahRAE />
    </RAEContextProvider>
  );
};

export default TambahRAE;
