import Services from "../../../services";

class AnalisaBarangJadiMarketingApi {
  getPage(params) {
    return Services.get("/analisa_barang_jadi/page", { params });
  }

  single(value) {
    return Services.get(`/analisa_barang_jadi/single`, {
      params: { ...value },
    });
  }

  getDropdown(params) {
    return Services.get("/analisa_barang_jadi/dropdown", { params });
  }
}

export default new AnalisaBarangJadiMarketingApi();
