import Services from "services";

class PenawaranApi {
  getPagePenawaran(params) {
    return Services.get("/penawaran/page", { params });
  }

  getNomorPenawaran(params) {
    return Services.get("/penawaran/no_baru", { params });
  }

  getPageListRAB(params) {
    return Services.get("/penawaran/list_rab", { params });
  }

  getSinglePenawaran(params) {
    return Services.get("/penawaran/single", { params });
  }

  getSingleRAB(params) {
    return Services.get("/penawaran/single_rab", { params });
  }

  save(data) {
    return Services.post("/penawaran/", data);
  }

  statusDeal(data) {
    return Services.put("penawaran/status_deal", data);
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }

  upload(data) {
    return Services.post("/upload/penawaran_multiple", data);
  }
}

export default new PenawaranApi();
