import React, { useEffect, useState, useContext } from "react";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5";
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton,
  Table,
  Td,
  InputCurrency,
} from "components";
import AnalisaBarangJadiRAE from "../AnalisaBarangJadi";
import { DecimalConvert, RupiahConvert } from "utilities";
import { RABContext } from "../RABContext";

const TableBarangJadi = ({
  dataBarangJadi,
  setDataBarangJadi,
  profit,
  onProfitChange,
  onInputRoundedChange,
  isDetail,
  action,
}) => {
  const {
    setIndexAnalisa,
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    type: contextType,
  } = useContext(RABContext);
  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 12);
    return convert;
  };

  const grandTotal = dataBarangJadi.reduce((acc, { rounded, qty }) => {
    const subtotal = parseInt(rounded ?? 0) * parseFloat(qty ?? 0);
    return parseInt(acc + subtotal);
  }, 0);

  const ModalSection = ({
    profit,
    processedData,
    modalConfig,
    setModalConfig,
    dataBarangJadi,
    setDataBarangJadi,
  }) => {
    const AnalisaBarangJadiModal = ({
      profit,
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAE
            profit={profit}
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
            action={action}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={"xl"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>

        <AnalisaBarangJadiModal
          profit={profit}
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          action={action}
        />
      </Modal>
    );
  };

  return (
    <>
      <div className="py-2 p-1">
        <b>List Barang Jadi</b>
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: "14px" }}
      >
        <thead className="text-center bg-light">
          <tr>
            <th rowSpan={2} style={{ width: "100px" }}>
              Kode Barang Jadi
            </th>
            <th rowSpan={2} className="align-middle" style={{ width: "350px" }}>
              Barang Jadi
            </th>
            <th rowSpan={2} className="align-middle">
              Qty
            </th>
            <th rowSpan={2} className="align-middle">
              Satuan
            </th>
            <th rowSpan={2} className="align-middle">
              Unit Cost
            </th>
            <th width={150} className="align-middle">
              Profit (%)
            </th>
            <th rowSpan={2} width={150} className="align-middle">
              Rounded
            </th>
            <th rowSpan={2} className="align-middle">
              Sub Total
            </th>
          </tr>
          <tr>
            <th style={{ padding: 0 }}>
              <Input
                noMargin
                readOnly={contextType === "DETAIL"}
                placeholder="Profit"
                className="text-right"
                value={profit}
                onChange={(e) => {
                  const value = e.target.value;
                  const convert = decimalConvert(value);

                  onProfitChange(convert);
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {dataBarangJadi?.map((val, index) => {
            const checkQty = val.qty ? parseFloat(val.qty) : 0;
            const harga = val.rounded ? parseFloat(val.rounded) : 0;
            const subTotal = parseFloat(checkQty * harga);

            return (
              <tr key={index}>
                <td>{val.kode_item}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIndexAnalisa(val.unique);
                      setProcessedData({
                        ...val,
                        index: index,
                      });
                      setModalConfig({
                        show: true,
                        type: "analisa",
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi RAB
                          </span>
                        ),
                      });
                    }}
                  >
                    {val.nama_item}
                  </a>
                </td>
                <td className="text-right">
                  {DecimalConvert(val.qty).getWithComa}
                </td>
                <td>{val.satuan}</td>
                <td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.total_analisa ?? 0).toString())
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.harga_profit ?? 0).toString())
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {/* <Input
                    noMargin
                    value={
                      val.rounded
                        ? RupiahConvert(String(parseFloat(val.rounded))).detail
                        : RupiahConvert(String(0)).detail
                    }
                    onChange={(e) => {
                      const value = e.target.value ? e.target.value : 0;
                      const parseToNumber = RupiahConvert(
                        String(value)
                      ).default;
                      const parseToRupiah = RupiahConvert(
                        String(parseToNumber)
                      ).detail;
                      e.target.value = parseToRupiah;
                      return onInputRoundedChange({
                        index: index,
                        value: parseToNumber,
                      });
                    }}
                    readOnly={isDetail ?? false}
                  /> */}
                  {console.log("test", val)}
                  <InputCurrency
                    value={parseFloat(val.rounded)}
                    onChange={(e) => {
                      // setFieldValue("harga_kesepakatan", e);
                      onInputRoundedChange({
                        index: index,
                        value: e,
                      });
                    }}
                    disabled={isDetail ?? false}
                  />
                </td>

                <td className="text-right">
                  {RupiahConvert(subTotal?.toString()).getWithComa}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
            </td>
          </tr>
        </tfoot>
      </table>
      {modalConfig.show && (
        <ModalSection
          profit={profit}
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      )}
    </>
  );
};

export default TableBarangJadi;
