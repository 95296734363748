import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { SuratPerjanjianKerjaApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataUnitProduksi, setDataUnitProduksi] = useState([{label: 'Semua', value: undefined}])
    const [dataBaseline, setDataBaseline] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        },
        {
            label: '6',
            value: '6'
        },
        {
            label: '7',
            value: '7'
        },
        {
            label: '8',
            value: '8'
        },
        {
            label: '9',
            value: '9'
        },
        {
            label: '10',
            value: '10'
        },
    ])
    const [loading, setLoading] = useState(true)
// GET DROPDOWN DATA
    const getDataDropdown = () => {
        Axios.all([
        SuratPerjanjianKerjaApi.getDropdownCustomer(),
        SuratPerjanjianKerjaApi.getDropdownUnitProduksi()
        ])
        .then(Axios.spread((customer, unit) => {
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataProyek = unit?.data?.data ? unit.data.data.map(val => ({label: val?.nama_unit_produksi, value: val?.id_unit_produksi})) : []

            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataUnitProduksi([{label: 'Semua', value: undefined}, ...mapDataProyek])
        }))
        .finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_penawaran_mulai: data?.tgl_penawaran_mulai,
        tgl_penawaran_selesai: data?.tgl_penawaran_selesai,
        tgl_rab_mulai: data?.tgl_rab_mulai,
        tgl_rab_selesai: data?.tgl_rab_selesai,
        customer: data?.customer,
        unit_produksi: data?.unit_produksi,
        baseline: data?.baseline,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ ...values, active: true })
        } else { 
          setData({ ...values, active: false })
        }
    
        setShow(false)
    }
// HANDLE CHANGE
    const onTanggalPenawaranChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_penawaran_mulai: startDate,
            tgl_penawaran_selesai: endDate
        })
    }
    const onTanggalRABChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null
    
        setValues({
          ...values,
          tgl_rab_mulai: startDate,
          tgl_rab_selesai: endDate
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_penawaran_mulai: undefined,
            tgl_penawaran_selesai: undefined,
            tgl_rab_mulai: undefined,
            tgl_rab_selesai: undefined,
            customer: undefined,
            unit_produksi: undefined,
            baseline: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Penawaran"
                        placeholderText="Pilih tanggal Penawaran"
                        startDate={values.tgl_penawaran_mulai ? new Date(values.tgl_penawaran_mulai) : ''}
                        endDate={values.tgl_penawaran_selesai ? new Date(values.tgl_penawaran_selesai) : ''}
                        onChange={dates => onTanggalPenawaranChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. RAB"
                        placeholderText="Pilih tanggal RAB"
                        startDate={values.tgl_rab_mulai ? new Date(values.tgl_rab_mulai) : ''}
                        endDate={values.tgl_rab_selesai ? new Date(values.tgl_rab_selesai) : ''}
                        onChange={dates => onTanggalRABChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => setFieldValue('customer', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.unit_produksi}
                        label="Unit Produksi"
                        placeholder="Pilih Unit Produksi"
                        defaultValue={dataUnitProduksi.find(val => val.value === values.unit_produksi)}
                        option={dataUnitProduksi}
                        onChange={val => setFieldValue('unit_produksi', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.baseline}
                        label="Baseline"
                        placeholder="Pilih Baseline"
                        defaultValue={dataBaseline.find(val => val.value === values.baseline)}
                        option={dataBaseline}
                        onChange={val => setFieldValue('baseline', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;