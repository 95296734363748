import Services from "services";

class RABApi {
  getPage(params) {
    return Services.get("/rab/page", { params });
  }

  getPageListRAE(params) {
    return Services.get("/rab/list_peluang", { params });
  }

  getNomorRAB(params) {
    return Services.get("/rab/no_baru", { params });
  }

  getSingleRAE(id_peluang) {
    return Services.get(`/rab/single_peluang?id_peluang=${id_peluang}`);
  }

  getSingleRAB(params) {
    return Services.get("/rab/single", { params });
  }

  save(data) {
    return Services.post("/rab/", data);
  }
  update(data) {
    return Services.put("/rab/", data);
  }

  getDropdownCustomer() {
    return Services.get("/peluang/customer");
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }
}

export default new RABApi();
