import Services from "../../../services";

class PeringkatPeluangApi {
  getPage(page, dataLength, key) {
    return Services.get(`/peringkat_peluang/page?page=${page}&per_page=${dataLength}&q=${key}`);
  }

  create(data) {
    return Services.post("/peringkat_peluang", data);
  }

  //   search(key) {
  //     return Services.get(`/peringkat_peluang/page?q=${key}`);
  //   }

  update(data) {
    return Services.put("/peringkat_peluang", data);
  }

  hide(data) {
    return Services.put("/peringkat_peluang/hide", data);
  }

  show(data) {
    return Services.put("/peringkat_peluang/show", data);
  }

  delete(data) {
    return Services.post("/peringkat_peluang/delete", data);
  }
}

export default new PeringkatPeluangApi();
