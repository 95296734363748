// Assets
import Logo from "../../../../../../assets/image/LogoAJB.png"

const KopSuratSection = () => (
	<div className="d-flex mx-2 mb-3">
		<div>
			<img src={Logo} width={110} alt="" />
		</div>
		<div style={{ marginLeft: 30 }}>
			<p>
				<br />
				<b>PT. Adi Jaya Beton</b>
				<br />
				Jl. Bypass Ngurah Rai No.229x, Sesetan, Denpasar Selatan, Kota Denpasar, Bali <br />
			</p>
		</div>
	</div>
)

export default KopSuratSection