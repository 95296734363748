import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import InfoSection from "./InfoSection";
import TableSection from "./TableSection";
import { RegistrasiPeluangApi, AnalisaBarangJadiApi } from "api";
import { DataStatus } from "components";
import { SPKContext } from "../SPKContext";

const AnalisaBarangJadi = ({
  processedData,
  dataBarangJadi,
  setDataBarangJadi,
  modalConfig,
  setModalConfig,
}) => {
  const { indexAnalisa } = useContext(SPKContext);
  const [data, setData] = useState({});
  const [dataListAnalisa, setDataListAnalisa] = useState([]);
  const [dataGambar, setDataGambar] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [isFetch, setIsFetch] = useState({ loading: false, success: true });

  const mapAnalisaRAB = (data) =>
    data?.map((item) =>
      Object({
        ...item,
        harga: item.harga,
      })
    );

  const getInitialData = () => {
    setIsFetch({ loading: true, success: true });

    Axios.all([
      AnalisaBarangJadiApi.getAnalisa({
        id_item_buaso: processedData?.id_barang_jadi,
      }),
    ])
      .then(
        Axios.spread((analisa) => {
          const dataAnalisa = analisa?.data?.data ?? {};
          const mapDataListAnalisa = mapAnalisaRAB(dataAnalisa?.analisa); // map data jika get single analisa RAE

          let gambar = [];
          let file = [];
          dataAnalisa?.files?.map((val) => {
            const generalFileType = val.path_gambar.split(".").pop();
            if (["jpg", "png", "gif", "jpeg"].includes(generalFileType)) {
              return gambar.push(val);
            }
            return file.push(val);
          });

          setData(dataAnalisa);
          setDataListAnalisa(mapDataListAnalisa);
          setDataGambar(gambar);
          setDataFile(file);

          setIsFetch({ loading: false, success: true });
        })
      )
      .catch((err) => setIsFetch({ loading: false, success: false }));
  };

  useEffect(() => {
    getInitialData();
    return () => {};
  }, []);

  return (
    <>
      {isFetch.loading ? (
        <DataStatus loading={isFetch.loading} text="Memuat data ... " />
      ) : !isFetch.success && !isFetch.loading ? (
        <DataStatus text="Gagal memuat data!" />
      ) : (
        <>
          <InfoSection
            data={data}
            dataGambar={dataGambar}
            dataFile={dataFile}
          />

          <TableSection
            data={dataListAnalisa}
            dataSelect={dataSelect}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </>
      )}
    </>
  );
};

export default AnalisaBarangJadi;
