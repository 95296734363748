import { DateConvert } from "utilities";

export const formInitialValues = (data) => ({
  id_info_peluang: data?.id_info_peluang ?? "",
  tgl_info_peluang: data?.tgl_info_peluang
    ? data?.tgl_info_peluang
    : DateConvert(new Date()).default,
  no_info_peluang: data?.no_info_peluang ? data?.no_info_peluang : "",
  keterangan: data?.keterangan ? data?.keterangan : "",
  status_info_peluang: data?.status_info_peluang
    ? data?.status_info_peluang
    : 1,
  tgl_status_info_peluang: data?.tgl_status_info_peluang
    ? data?.tgl_status_info_peluang
    : DateConvert(new Date()).default,
  id_customer: data?.id_customer ? data?.id_customer : "",
  id_proyek: data?.id_proyek ? data?.id_proyek : "",
  id_nama_marketing: data?.id_nama_marketing ? data?.id_nama_marketing : "",
  nama_customer: data?.nama_customer ?? "",
  nama_proyek: data?.nama_proyek ?? "",
  nama_marketing: data?.nama_marketing ?? "",
  nama_jabatan: data?.nama_jabatan ?? "",
  nama_unit_organisasi: data?.nama_unit_organisasi ?? "",
  status_peluang: data?.status_peluang ?? "",
  baseline: data?.status_info_peluang,
});
