import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
  ApprovalStatusButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { RABApi } from "api";
import { ModalFilter } from "./Section";

const RAB = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    active: state?.dataFilter?.active,
    tgl_rab_mulai: state?.dataFilter?.tgl_rab_mulai,
    tgl_rab_selesai: state?.dataFilter?.tgl_rab_selesai,
    tgl_peluang_mulai: state?.dataFilter?.tgl_peluang_mulai,
    tgl_peluang_selesai: state?.dataFilter?.tgl_peluang_selesai,
    customer: state?.dataFilter?.customer,
    unit_produksi: state?.dataFilter?.unit_produksi,
    baseline: state?.dataFilter?.baseline,
    status_approval: state?.dataFilter?.status_approval,
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    RABApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      tgl_rab_mulai: dataFilter?.tgl_rab_mulai,
      tgl_rab_selesai: dataFilter?.tgl_rab_selesai,
      tgl_peluang_mulai: dataFilter?.tgl_peluang_mulai,
      tgl_peluang_selesai: dataFilter?.tgl_peluang_selesai,
      customer: dataFilter?.customer,
      unit_produksi: dataFilter?.unit_produksi,
      baseline: dataFilter?.baseline,
      status_approval: dataFilter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const onInputSearch = (text) => {
    const key = text.target.value;
    if (searchTime) clearTimeout(searchTime)
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: key,
        });
        setPaginationConfig({
          page: "1",
          dataLength: "10",
          totalPage: "1",
          dataCount: "0",
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  useEffect(() => {
    setNavbarTitle("Rencana Anggaran Biaya (RAB)");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
    dataFilter?.active,
    dataFilter?.tgl_rab_mulai,
    dataFilter?.tgl_rab_selesai,
    dataFilter?.tgl_peluang_mulai,
    dataFilter?.tgl_peluang_selesai,
    dataFilter?.customer,
    dataFilter?.unit_produksi,
    dataFilter?.baseline,
    dataFilter?.status_approval,
  ]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISI",
        };
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECT",
        };

      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    };

    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}>
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Rencana Anggaran Biaya (RAB)</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    {val.status_approval === "REV" ||
                    val.status_approval === "APP" ? (
                      <>
                        <ReadButton
                          onClick={() =>
                            history.push(
                              `/transaksi/rab/detail/${val.id_rab}`,
                              { dataFilter }
                            )
                          }
                        />
                        <UpdateButton
                          onClick={() =>
                            history.push(`/transaksi/rab/ubah/${val.id_rab}`, {
                              dataFilter,
                            })
                          }
                        />
                      </>
                    ) : (
                      <ReadButton
                        onClick={() =>
                          history.push(`/transaksi/rab/detail/${val.id_rab}`, {
                            dataFilter,
                          })
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_rab)).custom}</div>
                  <div>{val.no_rab ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_peluang)).custom}</div>
                  <div>{val.no_peluang ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed textCenter>{val.baseline}</TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch onChange={onInputSearch}/>
            <FilterButton
              active={dataFilter.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ActionButton
            text="Tambah Data"
            onClick={() =>
              history.push("/transaksi/rab/list-peluang", { dataFilter })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default RAB;

// {/* <ReadButton onClick={() => history.push(`/transaksi/rab/detail/${val.id_rab}`)} />
//                     {(val.status_approval === "APP" || val.status_approval === "REV") && (
//                       <UpdateButton
//                         onClick={() => history.push(`/transaksi/rab/ubah/${val.id_rab}`)}
//                       />
//                     )} */}

//                     {/* <ReadButton onClick={() => history.push(`/transaksi/rab/detail/${val.id_rab}`)} />
//                     {val.status_approval === "PEN" ||
//                     val.status_approval === "REJ" ||
//                     val.status_approval === "VER" ||
//                     val.baseline !== val.baseline_terbaru ? (
//                       ""
//                     ) : (
//                       <UpdateButton
//                         onClick={() => history.push(`/transaksi/rab/ubah/${val.id_rab}`)}
//                       />
//                     )} */}
