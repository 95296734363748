import React, { useEffect, useState, useContext } from "react";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5";
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton,
  Table,
  Td,
  InputQtyDecimal,
} from "components";
import AnalisaBarangJadiRAE from "../AnalisaBarangJadi";
import { DecimalConvert, RupiahConvert } from "utilities";
import { PenawaranContext } from "../PenawaranContext";

const TableBarangJadi = ({
  dataBarangJadi,
  setDataBarangJadi,
  diskon,
  ppn,
  setFieldValue,
  profit,
}) => {
  const {
    setIndexAnalisa,
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    type: contextType,
  } = useContext(PenawaranContext);
  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 5);
    return convert;
  };

  const grandTotal = dataBarangJadi.reduce((acc, { rounded, qty }) => {
    const subtotal =
      parseFloat(rounded ? parseFloat(rounded) : 0) * parseFloat(qty ?? 0);
    return parseFloat(acc + subtotal);
  }, 0);

  const calculateDiskon = () => {
    const checkDiskon = diskon ? parseFloat(diskon) : 0;
    const totalDiskon = (checkDiskon * grandTotal) / 100;
    const totalSetelahDiskon = grandTotal - totalDiskon;

    return {
      totalDiskon: parseFloat(totalDiskon),
      totalSetelahDiskon: parseFloat(totalSetelahDiskon),
    };
  };

  const calculatePPN = () => {
    const checkPPN = ppn ? parseFloat(ppn) : 0;
    const totalSetelahDiskon = calculateDiskon().totalSetelahDiskon;
    const totalPPN = (checkPPN * totalSetelahDiskon) / 100;
    const totalSetelahPPN = totalSetelahDiskon + totalPPN;

    return {
      totalPPN: parseFloat(totalPPN),
      totalSetelahPPN: parseFloat(totalSetelahPPN),
    };
  };

  const ModalSection = ({
    rocessedData,
    modalConfig,
    setModalConfig,
    dataBarangJadi,
    setDataBarangJadi,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAE
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={"xl"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}>
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>

        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  return (
    <>
      <div className="py-2 p-1">
        <b>List Barang Jadi</b>
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: "14px" }}>
        <thead className="text-center bg-light">
          <tr>
            <th style={{ width: "100px" }}>Kode Barang Jadi</th>
            <th className="align-middle" style={{ width: "350px" }}>
              Barang Jadi
            </th>
            <th className="align-middle">Qty</th>
            <th className="align-middle">Satuan</th>
            <th className="align-middle">Harga Satuan</th>
            <th className="align-middle">Sub Total</th>
          </tr>
        </thead>
        <tbody>
          {dataBarangJadi?.map((val, index) => {
            const checkQty = val.qty ? parseFloat(val.qty) : 0;
            const harga = val.rounded ? parseFloat(val.rounded) : 0;
            const subTotal = parseFloat(checkQty * harga);

            return (
              <tr key={index}>
                <td>{val.kode_item}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIndexAnalisa(val.unique);
                      setProcessedData({
                        ...val,
                        index: index,
                      });
                      setModalConfig({
                        show: true,
                        type: "analisa",
                        title: (
                          <span className="text-primary">
                            Analisa Barang Jadi RAB
                          </span>
                        ),
                      });
                    }}>
                    {val.nama_item}
                  </a>
                </td>
                <td className="text-right">
                  {DecimalConvert(val.qty).getWithComa}
                </td>
                <td>{val.satuan}</td>
                <td className="text-right">
                  {RupiahConvert(parseFloat(harga ?? 0).toString()).getWithComa}
                </td>
                <td className="text-right">
                  {RupiahConvert(subTotal?.toString()).getWithComa}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={5} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
            </td>
          </tr>

          {/* DISKON */}
          <tr>
            <td colSpan={5} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>Diskon (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  <InputQtyDecimal
                    size="sm"
                    value={diskon}
                    disabled={contextType === "DETAIL"}
                    className="text-right"
                    onChange={(e) => {
                      setFieldValue("diskon", e);
                      // onPPNChange(convert)
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalDiskon))
                    .getWithComa
                }
              </b>
            </td>
          </tr>

          {/* TOTAL SETELAH DISKON */}
          <tr className="bg-light">
            <td colSpan={5} className="text-right py-2 align-middle">
              <b>Total Setelah Diskon</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalSetelahDiskon))
                    .getWithComa
                }
              </b>
            </td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={5} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>PPN (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                <InputQtyDecimal
                    size="sm"
                    value={ppn}
                    disabled={contextType === "DETAIL"}
                    className="text-right"
                    onChange={(e) => {
                      setFieldValue("ppn", e);
                      // onPPNChange(convert)
                    }}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculatePPN().totalPPN)).getWithComa}
              </b>
            </td>
          </tr>

          {/* TOTAL SETELAH PPN */}
          <tr className="bg-light">
            <td colSpan={5} className="text-right py-2 align-middle">
              <b>Total Setelah Pajak</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculatePPN().totalSetelahPPN))
                    .getWithComa
                }
              </b>
            </td>
          </tr>
        </tfoot>
      </table>
      {modalConfig.show && (
        <ModalSection
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      )}
    </>
  );
};

export default TableBarangJadi;
